import React from 'react';
import './Main.css';
import Cube from './Cube';


function Main() {
    return (
      <div className="main">
          <div className='main__paragraph'>
            <h1 className='title'>Fully managed property rentals</h1>
            <ul className='main__text'>
                We :
                <li className='main__li'>prepare your property for rental</li>
                <li className='main__li'>find guests and tenants</li>
                <li className='main__li'>manage your property</li>
                <li className='main__li'>have local expertise in Finland, Spain, France, UK</li>
                <li className='main__li'>develop and implement house automation</li>
                <li className='main__li'>run the buisness since 2007</li>
               <p className='main__span'>If you have property to rent and don’t want to hassle with the process, get in touch with us!</p>
            </ul>
            <a className='header__link' href="mailto:info@tefo.fi"><button className='main__button button'>Contact us</button></a>
          </div>
          <div className='main__img'>
            <Cube />
          </div>
      </div> 
    );
  }
  
  export default Main;
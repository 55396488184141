import React from 'react';
import './Cube.css';

function Cube() {
  return (
    <div className="container">
        <div className="flex">
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
        </div>
        <div className="flex">
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
        </div>
        <div className="flex">
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
        </div>
        <div className="flex">
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
            <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
            </div>
        </div>
</div>
  );
}

export default Cube;
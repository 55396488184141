import React from 'react';
import './Header.css';

function Header() {
  return (
    <div className="header">
        <a href="/" className="header__logo">Tefo Oy</a>
        <a className='header__link' href='https://www.tefo.fi/signin'>
            <button type='button' className='header__button button'>
              login
            </button> 
        </a>       
    </div> 
  );
}

export default Header;